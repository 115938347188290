<!-- Loading starts -->
<div id="loading-wrapper" *ngIf="isLodaing">
	<div class="lds-roller">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
	<!-- <div class="spinner-border" role="status">
		<span class="sr-only">Loading...</span>
	</div> -->
</div>
<!-- Loading ends -->
<!-- goBack -->
<div class="jumbotron" *ngIf="!isLodaing">
    <h2 style="color: #007c9d;"><a href="javascript:;" (click)="goBack()"><i class="arrow left"></i></a> Dohua Geotag</h2>
    <div class="row">
        <div class="col-lg-3 col-xs-12 col-sm-12" *ngFor="let doc of imageGallery; index as i" style="margin-top:10px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{doc.image_caption}}</h4>
                </div>
                <div class="modal-body">
                    <img src='{{endpoint}}/docs/{{doc.projectId}}/{{doc.original_file_name}}' alt="" height="200px"
                      width="285px" class="img-responsive" (click)="onImageClick(doc, i)"  style="cursor: pointer;"/>
                      <!-- (click)="onImageClick(doc, i)" -->
                    <div class="viewMapBtn">
                      <a style="float: right; margin-right: 10px; color: aliceblue;" routerLink="/pages/mapview/{{doc.GPSLatitude}}/{{doc.GPSLongitude}}">Map view </a>
                    </div>

                    <div
                      style="padding:10px;background-color:black;opacity:0.7;color:#ddc808;position:absolute;bottom:14px;z-index:999;margin-top:-90px;font-size:0.7em;font-weight:bold;width:285px;">
                      <div class="row">
                        <div class="col-lg-9" style="padding-right:0px;">
                          <span style="color:aliceblue !important;">Latitude:</span> {{doc.GPSLatitude}}
                          {{doc.GPSLatitudeRef}}<br>
                          <span style="color:aliceblue !important;">Longitude:</span> {{doc.GPSLongitude}}
                          {{doc.GPSLongitudeRef}}<br>
                          <span style="color:aliceblue !important;">Time:</span> {{doc.GPSTimeStamp}}<br>
                        </div>
                        <div class="col-lg-3" style="padding:0px;">
                          <img class="pull-left" style="opacity: 0.8;" src="assets/images/ashok-s.png" alt=""
                            height="40px" width="40px">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <span style="color:aliceblue !important;">Address:</span> {{doc.local_address}}
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isImageModalOpen" class="imageModal">
        <span class="close cursor" (click)="closeModal()">&times;</span>
        <div class="modal-content modal-content1 ">
          <div class="mySlides" (mouseWheelUp)="onScroll(1)">
            <img src='{{endpoint}}/docs/{{selectedPicture.projectId}}/{{selectedPicture.original_file_name}}' alt=""
              style="height: 90vh; width:100%; object-fit: fill;" (mouseleave)=mouseLeave(1) (mouseenter)=mouseEnter(2) />
            <div
              style="padding:10px;background-color:black;opacity:0.7;color:#ddc808;position:absolute;bottom:-2px;z-index:999;font-size:1.02em;font-weight:bold;width:100%;">
              <div class="row">
                <div class="col-lg-8">
                  <!-- uncomment to view map -->
                  <span style="color:aliceblue !important;">Latitude:</span> <a
                    routerLink="/mapview/{{selectedPicture.GPSLatitude}}/{{selectedPicture.GPSLongitude}}" style="color: rgb(93, 93, 241);">{{selectedPicture.GPSLatitude}}
                  </a>
                  {{selectedPicture.GPSLatitudeRef}}<br>
                  <span style="color:aliceblue !important;">Longitude:</span> <a
                    routerLink="/mapview/{{selectedPicture.GPSLatitude}}/{{selectedPicture.GPSLongitude}}" style="color: rgb(93, 93, 241);">{{selectedPicture.GPSLongitude}}</a>
                  {{selectedPicture.GPSLongitudeRef}}<br>
                  <span style="color:aliceblue !important;">Time:</span> {{selectedPicture.GPSTimeStamp}}<br>
                </div>
                <div class="col-lg-4">
                  <img class="pull-right" style="float:right;opacity: 0.9;" src="assets/images/ashok-s.png" alt=""
                    height="50px" width="50px">
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <span style="color:aliceblue !important;">Address:</span>
                  <a
                    routerLink="/mapview/{{selectedPicture.GPSLatitude}}/{{selectedPicture.GPSLongitude}}" style="color: rgb(93, 93, 241);">{{selectedPicture.local_address}}</a>
                </div>
              </div>
            </div>
          </div>
          <a class=" prev" (click)="changePhoto(1)">&#10094;</a>
          <a class="next" (click)="changePhoto(2)">&#10095;</a>
      
        </div>
      </div>
</div>
