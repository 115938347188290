<div class="bg">
    <!-- Container start -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                
            </div>
            <div class="col-md-4" style="margin-top:110px;border-radius:30px;">
                
                <div class="login-screen">
                    <div class="login-box" style="background: #ffffff; border-radius: 15px;opacity: 0.9;">
                        <div class="row justify-content-center">
                            
                                <a href="#" class="login-logo-1"
                            style="display:flex;align-items: center; font-size: 20px;">
                            <img src="assets/aiidc-logo2.png" alt="AIIDC" />
                        </a>
                            
                        </div>

                        
                        <br>
                        <p class="justify-content-md-center"><strong>Public Works Management System (Public Login)</strong></p>


                        <div *ngIf="!showOTPInput">
                            <div class="form-group">
                                <label for="phone">Phone number</label>
                                <input type="number" [(ngModel)]="userPhone" class="form-control" maxlength="10"
                                    placeholder="Enter registered phone number" />
                            </div>
                            <div class="actions mb-4">
                                <!-- <button  (click)="publicRegistration()" class="btn btn-warning" [disabled]="isLogin">Cancel</button> -->
                                <button (click)="publicRegistration()" class="btn btn-warning" [disabled]="isLogin">New
                                    Registration</button>
                                <button (click)="publicLogin()" class="btn btn-danger" [disabled]="isLogin">Login</button>
                            </div>
                        </div>




                        <div *ngIf="showOTPInput">
                            <div class="row">
                                <div class="col-lg-12">
                                    <label for="otp">Please enter the OTP</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-1"></div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                    <div class="form-group">
                                        <input type="number" [(ngModel)]="OTP.otp1" class="form-control" maxlength="1" />
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                    <div class="form-group">
                                        <input type="number" [(ngModel)]="OTP.otp2" class="form-control" maxlength="1" />
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                    <div class="form-group">
                                        <input type="number" [(ngModel)]="OTP.otp3" class="form-control" maxlength="1" />
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                    <div class="form-group">
                                        <input type="number" [(ngModel)]="OTP.otp4" class="form-control" maxlength="1" />
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding:0px;">
                                    <div class="form-group">
                                        <input type="number" [(ngModel)]="OTP.otp5" class="form-control" maxlength="1" />
                                    </div>
                                </div>
                            </div>
                            <div class="actions mb-4">
                                <button (click)="verifyOtpData()" class="btn btn-danger"
                                    [disabled]="isVerify">Verify</button>
                            </div>
                        </div>

                        <hr>
                    <a href="#" class="">
                        <p style="font-size:0.8em;color: #544e48; margin-left: 12px"><strong>Powered by</strong><img src="assets/skaplink.png" alt="AIIDCPMS" style="height: 15px; width: 180px; margin-right:28px; margin-right: 14px;" align="right"></p>
                    </a>

                    </div>
                </div>
            </div>
        </div>

        <!-- <form #form="ngForm"> -->
       


        <!-- </form> -->
    </div>
    <!-- Container end -->
</div>