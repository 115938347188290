
<div class="container">
 
        
       
    <div class="form-group">
      <label>Enter address</label>
      <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location For Reference" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
    </div>
   
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
      <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)"></agm-marker>
    </agm-map>
   
    <h5>Address: {{address}}</h5>
    <div>Latitude: {{latitude}}</div>
    <div>Longitude: {{longitude}}</div>
  </div>
   