<div id="print-section">
    <!-- Loading starts -->
    <div id="loading-wrapper" *ngIf="isLoading">
        <!-- <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div> -->
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <!-- Loading ends -->

    <!-- Container fluid start -->
    <div class="container-fluid p-0" *ngIf="!isLoading">
        <app-project-navbar projectId='{{projectId}}' activePage='feedback'></app-project-navbar>

        <div class="main-container">
            <!-- Page header start -->
            <div class="page-header">
                <div class="row">
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-6 text-center">
                        <p class="fontmobile" style="text-align: left;" title="{{projectDetails.name}}">
                            {{projectDetails.name}}</p>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-center" style="padding-right:0px;">
                            <div>
                                <div *ngIf="!isCitizen">
                                <a style="color: white;" href="#" data-toggle="modal" data-target="#addFeedback" data-placement="top" title=""
                                    data-original-title="Add Feedback">
                                    <i class="icon-add"></i>
                                    &nbsp;Add
                                </a>
                            </div>
                            <div *ngIf="isCitizen">
                                <a style="color: white;" href="javascript:;" (click)="goToRegistration()">
                                    <i class="icon-add"></i>
                                    &nbsp;Add
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="loading-wrapper" *ngIf="isLoading">
                <!-- <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div> -->
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
            <!-- Page header end -->

            <!-- Content wrapper start -->
            <div class="content-wrapper">
                <br>
                <h3>Feedbacks</h3>
                <p class="text-center" *ngIf="isFeedbackEmpty" style="color: black;"> No feedbacks Added</p>
                <div class="list">
                    <div *ngFor="let feedback of projectFeedbacks">

                        <div class="feedbackContainer">
                            <div class="row">
                                <div class="col-lg-1">
                                    <img src="{{feedback.user.avatar}}" alt="Profile" />
                                </div>
                                <div class="col-lg-11">
                                    <p>{{feedback.feedback}}</p>
                                    <p><strong>{{feedback.user.name}}</strong>&nbsp;&nbsp;&nbsp;{{feedback.createdAt | date:'medium'}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Content wrapper end -->
        </div>
    </div>



    <div class="modal fade" id="addFeedback" tabindex="-1" role="dialog" aria-labelledby="Feedback" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" id="addNewTaskLabel">Add Feedback</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="feedbackInput">Your Feedback</label>
                        <textarea class="form-control" id="feedbackInput" rows="5" [(ngModel)]="feedback"></textarea>
                    </div>
                </div>
                <div class="modal-footer custom">
                    <div class="left-side">
                        <button type="button" class="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
                    </div>
                    <div class="divider"></div>
                    <div class="right-side">
                        <button type="button" [disabled]="false" class="btn btn-link success btn-block"
                            (click)="submitFeedback()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>