
    const ulbList=[
    {
        name: "Baksa",
        MunicipalBoard:[
            {
                name:"Goreswar MB",
                assembly:"Rangia"
            }
        ]
    },
    {
        name: "Barpeta",
        MunicipalBoard:[
            {
                name:"Pathsala MB",
                assembly:"Patacharkuchi"
            },
            {
                name:"Barpeta MB",
                assembly:"Barpeta"
            },
            {
                name:"Barpeta Rd. MB",
                assembly:"Sorbhog"
            },
            {
                name:"Howly MB",
                assembly:"Barpeta"
            },
            {
                name:"Sorbhog MB",
                assembly:"Sorbhog"
            },
            {
                name:"Sarthebari MB",
                assembly:"Sarukhetri"
            },
            {
                name:"Patacharkuchi MB",
                assembly:"Patacharkuchi"
            }
        ]
    },
    {
        name: "Biswanath",
        MunicipalBoard:[
            {
                name:"B.Chariali MB",
                assembly:"Biswanath"
            },
            {
                name:"Gohpur MB",
                assembly:"Gohpur"
            },
            {
                name:"Sootea MB",
                assembly:"Sootea"
            }
        ]
    },
    {
        name: "Bongaigaon",
        MunicipalBoard:[
            {
                name:"Bongaigaon MB",
                assembly:"Bongaigaon"
            },
            {
                name:"Abhayapuri MB",
                assembly:"South Abhayapuri"
            }
        ]
    },
    {
        name: "Cachar",
        MunicipalBoard:[
            {
                name:"Silchar MB",
                assembly:"Silchar"
            },
            {
                name:"Lakhipur MB",
                assembly:"Lakhipur"
            },
            {
                name:"Sonai MB",
                assembly:"Sonai"
            }
        ]
    },
    {
        name: "charaideo",
        MunicipalBoard:[
            {
                name:"sonari MB",
                assembly:"Sonari"
            },
            {
                name:"Moran MB",
                assembly:"Moran"
            }
        ]
    },
    {
        name: "chirang",
        MunicipalBoard:[
            {
                name:"Kajalgaon MB",
                assembly:"Sidli/Bongaigaon"
            },
            {
                name:"Basugaon MB",
                assembly:"Sidli"
            },
            {
                name:"Bijni MB",
                assembly:"Bijni"
            }
        ]
    },
    {
        name: "Darrang",
        MunicipalBoard:[
            {
                name:"Mangaldoi MB",
                assembly:"Mangaldai"
            },
            {
                name:"Kharupetia MB",
                assembly:"Dalgaon"
            },
            {
                name:"Sipajhar MB",
                assembly:"Sipajhar"
            }
        ]
    },
    {
        name: "Dhemaji",
        MunicipalBoard:[
            {
                name:"Dhemaji MB",
                assembly:"Dhemaji"
            },
            {
                name:"Silapathar MB",
                assembly:"Jonai"
            }
        ]
    },
    {
        name: "Dhubri",
        MunicipalBoard:[
            
            {
                name:"Dhubri MB",
                assembly:"Dhubri"
            },
            {
                name:"Bilasipara MB",
                assembly:"Bilasipara East"
            },
            {
                name:"Gauripur MB",
                assembly:"Gauripur"
            },
            {
                name:"Chapar MB",
                assembly:"Bilasipara East"
            },
            {
                name:"Golokganj MB",
                assembly:"Golokganj"
            },
            {
                name:"Sapatgram MB",
                assembly:"Bilasipara West"
            }
        ]
    },
    {
        name: "Dibrugarh",
        MunicipalBoard:[
            {
                name:"Dibrugarh MB",
                assembly:"Dibrugarh"
            },
            {
                name:"Naharkatia MB",
                assembly:"Naharkatia"
            },
            {
                name:"Namrup MB",
                assembly:"Sonari"
            },
            {
                name:"Chabua MB",
                assembly:"Chabua"
            }
        ]
    },
    {
        name: "Dima Hasao",
        MunicipalBoard:[
            {
                name:"Haflong MB",
                assembly:"Haflong"
            },
            {
                name:"Umrangshu MB",
                assembly:"Haflong"
            },
            {
                name:"Maibong MB",
                assembly:"Haflong"
            },
            {
                name:"Mahur MB",
                assembly:"Haflong"
            }
        ]
    },
    {
        name: "Goalpara",
        MunicipalBoard:[
            {
                name:"Goalpara MB",
                assembly:"East. Goalpara"
            },
            {
                name:"Lakhipur  MB",
                assembly:"West Goalpara"
            }
        ]
    },
    {
        name: "Golaghat",
        MunicipalBoard:[
            
           
            {
                name:"Dergaon MB",
                assembly:"Dergaon"
            },
            {
                name:"Golaghat MB",
                assembly:"Golaghat"
            },
            {
                name:"Bokakhat MB",
                assembly:"Bokakhat"
            },
            {
                name:"Sarupathar MB",
                assembly:"Sarupathar"
            },
            {
                name:"Barpathar MB",
                assembly:"Sarupathar"
            }
        ]
    },
    {
        name: "Hailakandi",
        MunicipalBoard:[
            {
                name:"Hailakandi MB",
                assembly:"Hailakandi"
            },
            {
                name:"Lala MB",
                assembly:"Katlichera"
            }
        ]
    },
    {
        name: "Hojai",
        MunicipalBoard:[
            {
                name:"Lanka MB",
                assembly:"Hojai"
            },
            {
                name:"Hojai MB",
                assembly:"Hojai"
            },
            {
                name:"Lumding MB",
                assembly:"Lumding"
            },
            {
                name:"Doboka MB",
                assembly:"Jamunamukh"
            }
        ]
    },
    {
        name: "Jorhat",
        MunicipalBoard:[
            {
                name:"Jorhat MB",
                assembly:"Jorhat"
            },
            {
                name:"Moriani MB",
                assembly:"Moriani"
            },
            {
                name:"Titabar MB",
                assembly:"Titabar"
            },
            {
                name:"Teok MB",
                assembly:"Teok"
            }
        ]
    },
    {
        name: "Kamrup (R)",
        MunicipalBoard:[
            {
                name:"Rangia MB",
                assembly:"Rangia"
            },
            {
                name:"N. Guwahati MB",
                assembly:"Jalukbari"
            },
            {
                name:"Palasbari MB",
                assembly:"Palasbari"
            }
        ]
    },
    // {
    //     name: "Kamrup (M)",
    //     MunicipalBoard:[
    //         {
    //             name:"Guwahati Municipal Corporation1",
    //             assembly:"Dispur"
    //         },
    //         {
    //             name:"Guwahati Municipal Corporation2",
    //             assembly:"Guwahati East"
    //         },
    //         {
    //             name:"Guwahati Municipal Corporation3",
    //             assembly:"Guwahati West"
    //         },
    //         {
    //             name:"Guwahati Municipal Corporation4",
    //             assembly:"Jalukbari"
    //         }
    //     ]
    // },
    {
        name: "Karbi-Anglong",
        MunicipalBoard:[
            
            {
                name:"Diphu MB",
                assembly:"Diphu "
            },
            {
                name:"Bokajan MB",
                assembly:"Bokajan"
            },
            {
                name:"Langhin MB",
                assembly:"Howraghat/Diphu"
            },
            {
                name:"Bokolia MB",
                assembly:"Diphu"
            },
            {
                name:"Dokmoka MB",
                assembly:"Howraghat / Diphu"
            },
            {
                name:"Howaraghat MB",
                assembly:"Howaraghat"
            }
        ]
    },
    {
        name: "Karimganj",
        MunicipalBoard:[
            {
                name:"Karimganj MB",
                assembly:"Karimganj North"
            },
            {
                name:"Ramkrishnanagar MB",
                assembly:"Ratabari"
            },
            {
                name:"Badarpur MB",
                assembly:"Badarpur"
            }
        ]
    },
    {
        name: "Kokrajhar",
        MunicipalBoard:[
            {
                name:"Kokrajhar MB",
                assembly:"Kokrajhar East"
            },
            {
                name:"Gossaigaon MB",
                assembly:"Gossaigaon"
            },
            {
                name:"Fakiragram MB",
                assembly:"Kokrajhar west"
            }
        ]
    },
    {
        name: "Lakhimpur",
        MunicipalBoard:[
            {
                name:"N.Lakhimpur MB",
                assembly:"Lakhimpur"
            },
            {
                name:"Dhakuakhana MB",
                assembly:"Dhakuakhana"
            },
            {
                name:"Bihpuria MB",
                assembly:"Bihpuria"
            },
            {
                name:"Narayanpur MB",
                assembly:"Bihpuria"
            }
        ]
    },
    {
        name: "Morigaon",
        MunicipalBoard:[
            {
                name:"Morigaon MB",
                assembly:"Morigaon"
            }
        ]
    },
    {
        name: "Nagaon",
        MunicipalBoard:[
            {
                name:"Nagaon MB",
                assembly:"Sadar"
            },
            {
                name:"Kampur MB",
                assembly:"Barhampur"
            },
            {
                name:"Dhing MB",
                assembly:"Dhing/Batrawa"
            },
            {
                name:"Raha MB",
                assembly:"Raha"
            }
        ]
    },
    {
        name: "Nalbari",
        MunicipalBoard:[
            {
                name:"Nalbari MB",
                assembly:"Nalbari"
            },
            {
                name:"Tihu MB",
                assembly:"Patacharkuchi"
            }
        ]
    },
    {
        name: "Sivasagar",
        MunicipalBoard:[
            
           
            {
                name:"Sivasagar MB",
                assembly:"Sivasagar"
            },
            {
                name:"Nazira MB",
                assembly:"Nazira"
            },
            {
                name:"Demow MB",
                assembly:"Thowra"
            },
            {
                name:"Simaluguri MB",
                assembly:"Nazira"
            },
            {
                name:"Amguri MB",
                assembly:"Amguri"
            }
        ]
    },
    {
        name: "Sonitpur",
        MunicipalBoard:[
            {
                name:"Dhekiajuli MB",
                assembly:"Dhekiajuli"
            },
            {
                name:"Tezpur MB",
                assembly:"Tezpur"
            },
            {
                name:"Rangapara MB",
                assembly:"Rangapara"
            },
            {
                name:"Jamugurihat MB",
                assembly:"Sootea"
            }
        ]
    },
    {
        name: "Tinsukia",
        MunicipalBoard:[
            
            {
                name:"Margherita MB",
                assembly:"Margherita"
            },
            {
                name:"Digboi MB",
                assembly:"Digboi"
            },
            {
                name:"Doom Dooma MB",
                assembly:"Doom Dooma"
            },
            {
                name:"Tinsukia MB",
                assembly:"Tinsukia"
            },
            {
                name:"Makum MB",
                assembly:"Digboi"
            },
            {
                name:"Chapakhowa MB",
                assembly:"Sadiya"
            }
        ]
    },
    {
        name: "Udalguri",
        MunicipalBoard:[
            {
                name:"Tangla MB",
                assembly:"Paneri"
            },
            {
                name:"Udalguri MB",
                assembly:"Udalguri"
            }
        ]
    },
    {
        name: "West Karbianglong",
        MunicipalBoard:[
            {
                name:"Domkamokam MB",
                assembly:"Baithalangso"
            },
            {
                name:"Hamren MB",
                assembly:"Baithalangso"
            },
            {
                name:"Baithalangso MB",
                assembly:"Baithalangso"
            }
        ]
    },

]

export default ulbList;