<!-- Loading starts -->
<div id="loading-wrapper" *ngIf="isLodaing">
	<div class="lds-roller">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>

</div>
<!-- Loading ends -->
<!-- <div style="width: 50; height: 50; border-width: 1;"></div> -->
<!-- <div class="row"> -->
<!-- <div class="row mt-4"> -->
    <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <button (click)="redirect()" class="btn btn-warning">Home</button>
        <button (click)="redirect()" class="btn btn-success ml-5"><i class="icon-arrow-left"></i>&nbsp;Back to Home</button>
    </div> -->
    <div class="container p-0">
        <div class="row">
            <div class="col-12 text-center">
                <div id="map" style="width: 100%; height: 100vh;"></div>
            </div>
        </div>
    </div>
<!-- </div> -->
<!-- <div class="map" #map id="map" style="width: 97%; height: 70vh;position: absolute;z-index: 1;"></div> -->
<!-- <div class="map" #map id="map" style="width: 95%;height: 80vh;position: absolute;z-index: 1;margin-left: 50px;margin-top: 10px;border-radius: 10px;border: 1px solid #eae8e8;box-shadow: 0px 10px 20px #cccccc;"></div> -->