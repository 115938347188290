
<div class="root">
    <div class="bg">
        <div class="row pt-4">
            
            <div class="col-md-8">
            </div>
            <div class="col-md-4 pt-4">
                
                <div class="login-screen pt-4" >
                            <br>
                            <div class="login-box" style="background: #f1f1f1; border-radius: 15px;opacity: 0.9;">
                                <div class="row">
                            <!-- <div class="col-3" ></div> -->
                            <div class="col-12 text-center" style="text-align:center;">
                                <a href="javascript:;" class="login-logo">
                                    <img src="assets/aiidc-logo2.png" alt="AIIDCPMS"/>
                                </a>
                                <a href="javascript:;" class="login-logo">
                                    <img src="assets/Gati-Shakti-Logo.jpg" alt="AIIDCPMS" />
                                </a>
                            </div>
                        </div>
                        
                            
                            
                        <div class="row">
                            <div class="col-lg-12">
                                <p class="justify-content-md-center text-center" style="display:flex; padding-top: 10px; font-weight: 700; font-size: 22px; line-height: 100%;">State Data Center</p>
                                <p class="justify-content-md-center text-center" style="margin-top: 10px;"><strong>Project Monitoring and GIS System</strong></p>
                            </div>
                        </div>
                        
                        
                        <h5 style="color:#5684ad;margin-top: 10px;">Welcome to Tinsukia district,<br />Please Login to your Account.</h5>
                        <div class="form-group">
                            <input type="email" [(ngModel)]="userCredentials.email" class="form-control" placeholder="Email Address" />
                        </div>
                        <div class="form-group">
                            <input type="password" [(ngModel)]="userCredentials.password" class="form-control" placeholder="Password" (change)="login()"/>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button  (click)="login()" class="btn btn-tinsukia btn-block" [disabled]="isLogin">Login</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center pt-3">
                                    <a class="link" href="forgot-pwd.html">Forgot password?</a>
                            </div>
                        </div>
                        <hr>
                        <a href="#" class="">
                            <p style="font-size:0.8em;color: #544e48; margin-left: 12px"><strong>Design and developed by-</strong><img src="assets/skaplink.png" alt="AIIDC" style="height: 15px; width: 150px; margin-right:28px; margin-right: 14px;" align="right"></p>
                        </a>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>

    </div>
    
    
